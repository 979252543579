import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const SingleItem = ({ data }) => {
  const [SingleData, setSingleData] = useState([]);
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.stocks_id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/trade/market/stocks/view/${data?.stocks_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data.data);
          if (data.data) {
            const Symbol = `${data?.data?.symbol}`;
            fetchData(Symbol);
          }
        });
    }
  }, [data?.stocks_id]);

  const fetchData = async (Symbol) => {
    if (Symbol) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=YqPdwQOc_4caLUP1URqP`
      );
      const apiResponse = await data.json();
      if (apiResponse?.quotes) {
        setLivePrice(apiResponse?.quotes[0]);
        setLoading(false);
      }
    }
  };
  if (loading) {
    return;
  }
  return (
    <>
      <Link
        to={`/user/trade/stock/${SingleData?.symbol}/${SingleData?._id}`}
        style={{ borderBottom: "1px solid #dedede" }}
        className="d-flex shadow-md mx-2 p-2"
      >
        <div className="align-self-center d-flex">
          <span className="icon rounded-s me-2 gradient-orange shadow-bg shadow-bg-s">
            <LazyLoadImage
              effect="blur"
              alt="img"
              className="coin_style_div_div-img"
              style={{ borderRadius: "50%" }}
              src={`https://api.prospectstreetx.com/${SingleData?.image}`}
            />
          </span>
        </div>
        <div className="align-self-center ps-1">
          <h5 style={{ color: "black" }} className="pt-1 text-base mb-n1">
            {SingleData?.name}
          </h5>
          <p style={{ color: "green" }} className="mb-0 font-11 opacity-50">
            ${livePrice?.bid}
            <span className="copyright-year"></span>
          </p>
        </div>
        <div className="align-self-center ms-auto text-end">
          <h4 style={{ color: "gray" }} className="pt-1 mb-n1 text-xs">
            Trade
          </h4>
        </div>
      </Link>
    </>
  );
};

export default SingleItem;
