import React, { useEffect, useState } from "react";

const SingleItem = ({ data, index, livePrice }) => {
  // const [LiveData, setLiveData] = useState({});
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData();
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [LiveData]);

  // const fetchData = async () => {
  //   if (data?.market === "crypto") {
  //     fetch(
  //       `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:${data?.symbol}${data?.currency}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
  //       {
  //         method: "GET",
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data?.ticker) {
  //           setLiveData(data?.ticker);
  //         }
  //         setLoading(false);
  //       });
  //   } else if (data?.market === "forex") {
  //     fetch(
  //       `https://api.polygon.io/v2/snapshot/locale/global/markets/forex/tickers/C:${data?.symbol}${data?.currency}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
  //       {
  //         method: "GET",
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data?.ticker) {
  //           setLiveData(data?.ticker);
  //         }
  //         setLoading(false);
  //       });
  //   }
  // };

  // if (loading) {
  //   return;
  // }
  // if(LiveData?.ticker){
  //   return (
  //     <>
  //       <div className="row popular-market-list">
  //         <div className="col-6">
  //           <h6>
  //             {data?.name}
  //             <span>/{data?.currency}</span>
  //           </h6>
  //         </div>
  //         <div className="col-6 ">
  //           <div className="row popular-market-list-right">
  //             <div className="col-6 ">
  //               <h6>
  //                 {!isNaN(LiveData?.lastQuote?.a) &&
  //                   parseFloat(LiveData?.lastQuote?.a)}
  //                 {!isNaN(LiveData?.lastTrade?.p) &&
  //                   parseFloat(LiveData?.lastTrade?.p)}
  //                 $
  //               </h6>
  //             </div>
  //             <div className="col-6 d-flex justify-content-end align-items-center">
  //               {LiveData?.todaysChangePerc < 0 ? (
  //                 <span className="btn-2 bg-danger">
  //                   {parseFloat(LiveData?.todaysChangePerc).toFixed(2)}%
  //                 </span>
  //               ) : (
  //                 <span className="btn-2">
  //                   {parseFloat(LiveData?.todaysChangePerc).toFixed(2)}%
  //                 </span>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  ///////////////////////////////////////////////////////////////////////////

  if (livePrice[index]?.bid) {
    return (
      <>
        <div className="row popular-market-list">
          <div className="col-6">
            <h6>
              {data?.name}
              <span>/{data?.currency}</span>
            </h6>
          </div>
          <div className="col-6 ">
            <div className="row popular-market-list-right text-end">
              <div className="col-12 ">
                <h6>{livePrice[index]?.bid}$</h6>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SingleItem;
